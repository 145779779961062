import * as ReactDOM from 'react-dom';
import * as React from 'react';
import {HashRouter, Link, Route, Switch} from 'react-router-dom';
import './app.scss';
import {useCallback, useEffect, useState} from 'react';
import {
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
} from 'reactstrap';
import {LoginButton, LogoutButton, SessionProvider, useSession} from '@inrupt/solid-ui-react';
import {Button, MenuItem, Select, Switch as MuiSwitch} from '@material-ui/core';
import {SolidDashboard} from "./solid";
import {usePromiseFn} from "./utils/hooks";
import {EpcSummary, getPodUrls} from "belfius-core";
import {PromiseStateContainer} from "./utils/ui-utils";
import {getBackendUrl} from "./config";
import {useLocation} from "react-router";

export type AppContextType = {
    updateCtx: (update: Partial<AppContextType>) => void;
};

function createInitAppContext(updateAppContextFn: (update: Partial<AppContextType>) => void, basemap?: string): AppContextType {
    return {
        updateCtx: updateAppContextFn
    };
}

export const AppContext = React.createContext<AppContextType>(createInitAppContext(() => null));

export function AppContextProvider(props: { children: (ctx: AppContextType) => React.ReactNode }) {

    const [appContext, setAppContext] = useState<AppContextType>(
        createInitAppContext(function (update) {
            setAppContext((prevCtx: AppContextType) => ({...prevCtx, ...update}));
        })
    );

    useEffect(
        () => {
            // TODO init ctx

            appContext.updateCtx({
                /* */
            });
        },
        // run this only once
        []
    );

    return <AppContext.Provider value={appContext}>{props.children(appContext)}</AppContext.Provider>;
}

export const AppNavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {session} = useSession();

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar expand="md" className="dashboard-navbar">
            <NavbarBrand href="/">
                <div aria-hidden="true" data-initials="" className="bl-main-navigation__image"><span
                    aria-hidden="true"
                    className="bl-main-navigation__image__icon vl-icon vl-vi vl-vi-burgerprofiel"></span>
                </div>
                <span className="title">Mijn BurgerProfiel</span>
            </NavbarBrand>
            <NavbarToggler onClick={toggle}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="me-auto">
                    <NavItem>
                        <NavLink tag={Link} to="/">
                            Welcome
                        </NavLink>
                    </NavItem>
                    {session.info.isLoggedIn ?
                        <NavItem>
                            <NavLink tag={Link} to="/solid">
                                Dashboard
                            </NavLink>
                        </NavItem> : null}
                    <NavItem>
                        <NavLink href="/belfius_extension.zip">
                            Extension
                        </NavLink>
                    </NavItem>

                </Nav>
                <Nav className="mr-auto">
                    <NavItem>
                        {session.info.isLoggedIn ? (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <NavLink style={{display: 'inline'}} tag={Link} to="/user">
                                    <i className="fa fa-user"/> {session.info.webId}
                                </NavLink>
                                <LogoutButton>
                                    <Button variant="contained" color="primary">
                                        Log&nbsp;out
                                    </Button>
                                </LogoutButton>
                            </div>
                        ) : null}
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

const ISSUERS = {
    "https://openid.sandbox-pod.datanutsbedrijf.be": "DNB Sandbox",
    "https://inrupt.net": "Inrupt.net",
    "https://solidcommunity.net/": "Solid Community",
    "https://login.inrupt.com/": "Inrupt Pod Spaces",
    "https://idp.use.id/": "use.id",
    "http://localhost:3000/": "Localhost Solid"
}

function removeTrailingSlash(url: string) {
    if (url.endsWith('/')) url = url.substring(0, url.length - 1);

    return url;
}

export const LoginMultiButton = () => {
    const [issuer, setIssuer] = useState("https://openid.sandbox-pod.datanutsbedrijf.be");

    return (
        <LoginButton
            authOptions={
                {
                    clientId: "https://epc.datavillage.me/appid"
                    /* tokenType: 'Bearer'*/
                    /*, popUp: true */
                }
            }
            oidcIssuer={issuer}
            // this is the ID issuer for the DNB sandbox
            redirectUrl={removeTrailingSlash(new URL("/", window.location.href).toString())}
            onError={console.log}
        >
            <Button variant="contained" color="primary">
                Log in with
                <Select
                    value={issuer}
                    onChange={(e) => {
                        setIssuer(e.target.value as string);
                        e.stopPropagation()
                    }}
                >
                    {Object.keys(ISSUERS).map(uri => <MenuItem value={uri} key={uri}>{ISSUERS[uri]}</MenuItem>)}
                </Select>
            </Button>
        </LoginButton>
    );
};


export const Welcome = () => {
    const {session} = useSession();

    const podUrl$ = usePromiseFn(async () => session.info.webId ? getPodUrls(session.info.webId, {fetch: session.fetch}).then(urls => urls?.length ? urls[0] : undefined) : undefined, [session.fetch, session.info.webId]);

    return (
        <div>
            <h2>Welcome to the Energy Dashboard</h2>
            <div>
                The energy dashboard helps you manage your energy-related datasets like your EPC documents, and grant
                access to theses documents to third-party service providers.
            </div>
            <br/>
            {session.info.isLoggedIn ?
                <div>
                    <div>You are logged in as {session.info.webId}</div>
                    <PromiseStateContainer state={podUrl$}>{(url) => <>
                        <AccessGrantButton podUrl={url}/>
                    </>}</PromiseStateContainer>
                </div> :
                <div>
                    Please log in to manage your EPC data
                    <LoginMultiButton/>
                </div>
            }
        </div>
    );
}


export const AccessGrantButton = (props: { podUrl?: string }) => {
    const {session} = useSession();

    const epcMetadata$ = usePromiseFn(async () => session.info.webId && fetch(getBackendUrl("/epc/metadata?webId=" + encodeURIComponent(session.info.webId))).then(resp => resp.json() as Promise<any[]>), [session.info.webId]);

    const setGrantStatus = useCallback((epcUri: string, checked: boolean) => {
        fetch(getBackendUrl("/epc/grantStatus?epcUri=" + encodeURIComponent(epcUri) + "&enabled=" + checked), {method: 'POST'});
    }, []);

    return props.podUrl ?
        <PromiseStateContainer state={epcMetadata$}>{(md) =>
            (md && md.length) ? <><MuiSwitch checked={md[0].enabled}
                                             onChange={(event, checked) => setGrantStatus(md[0].uri, checked)}/> Grant
                access to banks</> : null
        }</PromiseStateContainer> :
        <>"No pod found"</>
        ;
}


export const Signin = () => {

    const location = useLocation();
    const redirectUri = new URLSearchParams(location.search).get("redirectUri");
    const method = new URLSearchParams(location.search).get("method")

    return (
        <div>
            <h2>Welkom bij the Energie Dashboard</h2>

            <a href={`/#/consent?method=${encodeURIComponent(method || '')}&redirectUri=${encodeURIComponent(redirectUri || '')}`}><img src="images/itsme.png" /></a>
        </div>
    );
}





const EPC_RANGES: { [key: string]: { range: [number, number], sample: number } } = {
    "A": {range: [0, 100], sample: 50},
    "B": {range: [100, 200], sample: 150},
    "C": {range: [200, 300], sample: 250},
    "D": {range: [300, 400], sample: 350},
    "E": {range: [400, 500], sample: 450},
    "F": {range: [500, 600], sample: 550},
    "G": {range: [600, 10000], sample: 650},
}

function getScore(value_kwhm2: number) {
    const match = Object.entries(EPC_RANGES).find(([score, range]) => value_kwhm2 > range.range[0] && value_kwhm2 < range.range[1]);

    return match && match[0];
}



export const Consent = () => {

    const [enabled, setEnabled] = useState(true);

    const location = useLocation();
    const redirectUri = new URLSearchParams(location.search).get("redirectUri")
    const method = new URLSearchParams(location.search).get("method")

    const epcUri = 'https://storage.sandbox-pod.datanutsbedrijf.be/9732713a-eb64-40d1-b948-a4ce6045351f/energy/epc.ttl';

    const epcSummary$ = usePromiseFn(async () => fetch(getBackendUrl("/epc/summary?uri=" + encodeURIComponent(epcUri))).then(resp => resp.json() as Promise<EpcSummary>), [epcUri]);

    return (
        <div>
            <h2>Welkom bij the Energie Dashboard</h2>
            <div>
                Het energiedashboard helpt u bij het beheren van uw energiegerelateerde datasets zoals uw EPC-documenten
                en het verlenen van toegang tot deze documenten aan externe dienstverleners.
            </div>
            <div>
                <h4>Samenvatting van energiegegevens</h4>
                <PromiseStateContainer state={epcSummary$}>{(currentEpcData) =>
                    currentEpcData ?
                    <table style={{borderSpacing: "15px 2px"}}>
                        <tbody>
                        <tr>
                            <td>Locatie</td>
                            <td>{currentEpcData['energie:Ligging']['energie:Adres']['generiek:Gemeente']['generiek:PostCode']} {currentEpcData['energie:Ligging']['energie:Adres']['generiek:Gemeente']['generiek:Naam']}</td>
                        </tr>
                        <tr>
                            <td>Bouwvorm</td>
                            <td>{currentEpcData['energie:Details']['energie:Bouwvorm']}</td>
                        </tr>
                        <tr>
                            <td>Energiescore</td>
                            <td><b>{getScore(currentEpcData['energie:Scores']['energie:Score']['energie:Waarde'])}</b>&nbsp;
                                <span
                                    style={{color: currentEpcData['energie:Scores']['energie:ScoreKleur']['energie:Code']}}>⬤</span>&nbsp;
                                {currentEpcData['energie:Scores']['energie:Score']['energie:Waarde']} kWh/m².an
                            </td>
                        </tr>
                        </tbody>
                    </table> : null
                }</PromiseStateContainer>

            </div>
            <br/>
            <MuiSwitch checked={enabled} onChange={(event, checked) => setEnabled(checked)}/>
            Toegang verlenen aan Belfius <img src="images/Belfius.png" width="200px"/> <br/>
            <Button variant="contained" color="primary" onClick={() => {

                if (redirectUri) {
                    if (window.opener && method == 'message') {
                        window.opener.postMessage({epcUri}, redirectUri);
                        window.close();
                    } else {
                        window.location.href = redirectUri + "?epcUrl=" + encodeURIComponent(epcUri)
                    }
                }
            }
            }>Bevestigen</Button>
        </div>
    );
}


const routes = [
    {
        component: Welcome,
        exact: true,
        path: '/'
    },
    {
        component: SolidDashboard,
        exact: false,
        path: '/solid'
    },
    {
        component: Consent,
        exact: false,
        path: '/consent'
    },
    {
        component: Signin,
        exact: false,
        path: '/login'
    }
];

export const App = () => {
        return (
            <SessionProvider onError={console.log}>
                <HashRouter>
                    <AppContextProvider>
                        {ctx => (
                            <div className="dashboardApp vFlow">
                                <AppNavBar/>
                                <div style={{padding: '20px 200px'}}>
                                    <Switch>
                                        {routes.map((route, i) => (
                                            <Route exact={route.exact} path={route.path} key={i}>
                                                <route.component/>
                                            </Route>
                                        ))}
                                    </Switch>
                                </div>
                            </div>
                        )}
                    </AppContextProvider>
                </HashRouter>
            </SessionProvider>
        );
    }
;

ReactDOM.render(
    <App/>
    , document.getElementById('index'));
